import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { Input } from "../Form"
import Textarea from "../Form/Textarea"
import { increaseStep, updateField } from "./actions"
import SupporterSignupContext from "./context"
import FormPage from "./FormPage"
import { useUsStates } from "../../hooks/useUsStatesList"
import Select from "../Form/Select"

const Info = ({ index, title, paragraph, ctaText }) => {
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidZipCode, setInvalidZipCode] = useState(false)
  const [optIn, setOptIn] = useState(false)

  const usStates = useUsStates()

  const [
    {
      active_step,
      name,
      email,
      phone,
      use_name,
      mailing_street_address,
      mailing_city,
      mailing_state,
      mailing_zip_code,
    },
    dispatch,
  ] = useContext(SupporterSignupContext)

  const validateForm = e => {
    e.preventDefault()
    setInvalidEmail(false)
    setInvalidZipCode(false)

    const email = document.getElementById("supporter_email")
    const zip_code = document.getElementById("supporter_zip_code")?.value;

    const validEmail = email.checkValidity()
    const validZipCode = /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip_code)

    if (!validEmail) setInvalidEmail("Invalid email.")

    if(!validZipCode) setInvalidZipCode("Invalid zip code.")

    if(validEmail && validZipCode) {
      dispatch(increaseStep())
    }
    
  }

  return (
    <FormPage
      show={active_step === index}
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonOnClick={validateForm}
      nextButtonDisabled={
        !name ||
        !email ||
        !phone ||
        !mailing_street_address ||
        !mailing_city ||
        !mailing_state ||
        !mailing_zip_code ||
        !optIn
      }
    >
      <form id="sponsor_info" css={[tw`px-8 lg:px-16`]}>
        <Input
          name="name"
          label="Name"
          value={name}
          onChange={e => dispatch(updateField("name", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          id="supporter_email"
          name="email"
          label="Email"
          type="email"
          value={email}
          onChange={e => dispatch(updateField("email", e.target.value))}
          css={[tw`mb-8`]}
          error={invalidEmail}
        />
        <Input
          name="phone"
          label="Contact Phone"
          type="tel"
          value={phone}
          onChange={e => dispatch(updateField("phone", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="mailing_street_address"
          label="Addresss"
          value={mailing_street_address}
          onChange={e => dispatch(updateField("mailing_street_address", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="mailing_city"
          label="City"
          value={mailing_city}
          onChange={e => dispatch(updateField("mailing_city", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Select 
          name="mailing_state"
          label="State"
          value={mailing_state}
          onChange={e => dispatch(updateField("mailing_state", e.target.value))}
          css={tw`mb-8 h-12`}
        >
          <option css={tw`bg-black bg-opacity-70 text-white`}></option>
          { usStates.map((state, index) => (
            <option key={index} value={state} css={tw`bg-black bg-opacity-70 text-white`}>
             {state}
            </option>
           ))
          }
        </Select>
        <Input
          id="supporter_zip_code"
          name="mailing_zip_code"
          label="Zip Code"
          type="text" 
          value={mailing_zip_code}
          onChange={e => dispatch(updateField("mailing_zip_code", e.target.value))}
          error={invalidZipCode}
          css={[tw`mb-2`]}
        />
        <p css={[tw`text-xs mb-8`]}>
          By submitting, you agree to our{" "}
          <a href="/terms-of-use" target="_blank" css={[tw`underline`]}>
            Terms
          </a>
          {" "}and{" "}
          <a href="/privacy-policy" target="_blank" css={[tw`underline`]}>
            Privacy Policy
          </a>
          .
        </p>
        <label for="supporter_use_name" css={[tw`inline-flex mb-4`]}>
          <input
            id="supporter_use_name"
            name="supporter_use_name"
            type="checkbox"
            css={[tw`sr-only`]} 
            onChange={e => dispatch(updateField("use_name", e.target.checked))}
          />
          <div css={[tw`border-2 border-primary h-6 w-6 rounded mr-2 p-2 lg:p-0.5`]}>
            <div css={[tw`w-full h-full rounded-sm`]} />
          </div>
          <span>
            Allow my name to be shown as a supporter on the TTC website or marketing materials
          </span>
        </label>
        <label for="supporter_opt_in" css={[tw`inline-flex mb-8`]}>
          <input
            id="supporter_opt_in"
            name="supporter_opt_in"
            type="checkbox"
            css={[tw`sr-only`]} 
            onChange={e => setOptIn(e.target.checked)}
          />
          <div css={[tw`border-2 border-primary h-6 w-6 rounded mr-2 p-2 lg:p-0.5`]}>
            <div css={[tw`w-full h-full rounded-sm`]} />
          </div>
          <span>
            I agree to TTC's Terms and Privacy Policy
          </span>
        </label>
      </form>
    </FormPage>
  )
}

export default Info
